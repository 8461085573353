import * as React from 'react';
import * as Yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { useMutationCreateTransaction } from 'repositories/transaction/transaction.mutation';
import useAppState from './useAppState';
import { useQueryGetPayments } from 'repositories/payment';
import { useQueryGetBooking } from 'repositories/booking/booking.query';
import { useFormik } from 'formik';
import { isPast, isToday } from 'date-fns';
import i18n from 'locales';
import { toast } from 'react-toastify';
import { Voucher } from 'repositories/voucher/voucher.model';
import { v4 as uuidv4 } from 'uuid';
import {
  TransactionProduct,
  TransactionService,
  Transaction,
} from 'repositories/transaction';
import { useTranslation } from 'react-i18next';
import { Member } from 'repositories/auth';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';

const YupSchema = Yup.object().shape({
  id: Yup.string().required(),
  isMemberCode: Yup.bool().notRequired(),
  memberCode: Yup.string().when('isMemberCode', {
    is: true,
    then: Yup.string().required(),
  }),
  phoneNumber: Yup.string().when(['isGuest', 'isMemberCode'], {
    is: (isGuest, isMemberCode) => !isGuest && !isMemberCode,
    then: Yup.string().required(),
  }),
  outletId: Yup.string().required(),
  services: Yup.array(),
  products: Yup.array().when('services', {
    is(services) {
      return !(services || []).length;
    },
    then: Yup.array()
      .min(1)
      .required(i18n.t('error.eitherProductOrServiceIsRequired')),
  }),
  voucherCode: Yup.string(),
  bookingId: Yup.string(),
  stylistId: Yup.string().nullable(),
  paymentId: Yup.string().required(),
  email: Yup.string()
    .email()
    .when(['isGuest', 'memberCheckResult'], {
      is(isGuest, memberCheckResult?: MemberCheckResult) {
        return isGuest || !memberCheckResult?.exist;
      },
      then: Yup.string().email().required(),
    }),
  customerName: Yup.string().when(['isGuest', 'memberCheckResult'], {
    is(isGuest, memberCheckResult?: MemberCheckResult) {
      return isGuest || !memberCheckResult?.exist;
    },
    then: Yup.string().required(),
  }),
  payAmount: Yup.number().min(Yup.ref('totalPay')).required(),
  transactionAt: Yup.date(),
  otp: Yup.string().when('transactionAt', {
    is(transactionAt) {
      return !!transactionAt;
    },
    then: Yup.string().required(),
  }),
  // below is client only
  voucher: Yup.mixed().when('voucherCode', {
    is(voucherCode) {
      return !!voucherCode;
    },
    then: Yup.mixed().nullable(false),
  }),
  totalPay: Yup.number().required(),
  subTotal: Yup.number().required(),
  discount: Yup.number(),
  isGuest: Yup.boolean().required(),
  mode: Yup.boolean(),
  memberCheckResult: Yup.object().when(['isGuest', 'mode'], {
    is: (isGuest, mode) => !isGuest && mode,
    then: Yup.object().required(),
  }),
});

interface TransactionOption {
  bookingData?: any;
  bookingId?: string;
  date: Date;
  onSuccess?: (transaction: Transaction) => void;
}

interface Option {
  label: string;
  value: string;
}

export interface MemberCheckResult {
  exist: boolean;
  data?: Member;
}

export default function useTransactionForm(options: TransactionOption) {
  const [createTransaction] = useMutationCreateTransaction();
  const [isLoading, setIsLoading] = React.useState(false);
  const apolloClient = useApolloClient();
  const { currentOutlet } = useAppState();
  const outletId = currentOutlet?.id || '';
  const [, setTimeInMinutes] = React.useState(0);
  const [paymentMethods, setPaymentMethods] = React.useState<Option[]>([]);
  const { t } = useTranslation();

  const { mode } = useNetworkStatus();

  const isPastDate = isPast(options.date) && !isToday(options.date);

  const formik = useFormik({
    initialValues: {
      id: uuidv4(),
      phoneNumber: '',
      outletId,
      stylistId: '',
      services: [] as TransactionService[],
      products: [] as TransactionProduct[],
      voucherCode: '',
      bookingId: options.bookingId || '',
      paymentId: undefined as string | undefined,
      paymentLabel: '',
      customerName: '',
      email: '',
      payAmount: 0,
      transactionAt: isPastDate ? options.date : undefined,
      otp: undefined as string | undefined,
      otpMember: undefined as string | undefined,
      // below is client only
      voucher: null as Voucher | null,
      totalPay: 0,
      subTotal: 0,
      discount: 0,
      mode,
      memberCheckResult: undefined as MemberCheckResult | undefined,
      isGuest: false,
      isMemberCode: false,
      memberCode: '',
    },
    validateOnMount: true,
    validationSchema: YupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const bookedServices = values.services.map((service) => {
        return service.service.id;
      });
      const bookedProducts = values.products.map((product) => {
        return {
          id: product.product.id,
          qty: product.qty,
        };
      });

      try {
        const { data } = await createTransaction({
          variables: {
            payload: {
              id: values.id,
              outlet_id: values.outletId,
              service_id: mode ? bookedServices : values.services,
              products: mode ? bookedProducts : values.products,
              voucher_code: values.voucher ? values.voucherCode : undefined,
              booking_id: values.bookingId || undefined,
              payment_id: values.paymentId || '',
              pay_amount: values.payAmount,
              phone_number: values.phoneNumber || undefined,
              customer_name: values.customerName || undefined,
              email: values?.email || undefined,
              otp_member: values?.otpMember || undefined,
              ...(values.transactionAt
                ? {
                    otp: values.otp,
                    transaction_at: values.transactionAt.toISOString(),
                  }
                : {}),
              ...(!mode
                ? {
                    status: 2,
                    stylistId: values.stylistId || '',
                    totalPay: values.totalPay,
                  }
                : {}),
            },
          },
        });
        if (options.onSuccess && data?.transaction.data) {
          options.onSuccess(data.transaction.data);
        }
        toast.success(t('transaction.transaction_created_successfully'));
        apolloClient.reFetchObservableQueries();
      } catch (e) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  useQueryGetPayments(
    React.useCallback((data) => {
      if (data && data.payments && data.payments.data) {
        setPaymentMethods(
          data.payments.data.map((payment: any) => ({
            value: payment.id,
            label: payment.name,
          })),
        );
      }
    }, []),
  );

  const {
    data: bookingResultData,
    loading: isLoadingBooking,
  } = useQueryGetBooking(options.bookingId, (bookingResultData) => {
    const { data } = bookingResultData.booking;

    formik.setValues({
      ...formik.values,
      phoneNumber: data.member.phoneNumber || '',
      customerName: data.member.name || '',
      email: data.member.email || '',
      isGuest: !data.member.id,
      bookingId: data.id,
      services: data.services.map((service) => ({
        id: service.service.id,
        price: service.price,
        totalPrice: service.price,
        qty: 1,
        timeInMinutes: service.timeInMinutes,
        service: {
          id: service.service.id,
          name: service.service.name,
          imagePath: service.service.imagePath,
          price: service.price,
          minPrice: service.service.minPrice,
          maxPrice: service.service.maxPrice,
          minRegularPrice: service.service.minRegularPrice,
          maxRegularPrice: service.service.maxRegularPrice,
          point: service.service.point,
          timeInMinutes: service.timeInMinutes,
          isPriceList: service.service.isPriceList,
          regularPrice: service.service.regularPrice,
        },
      })),
      products: data.products.map((product) => ({
        price: product.price,
        totalPrice: product.price * product.qty,
        qty: product.qty,
        product: {
          id: product.product.id,
          name: product.product.name,
          point: product.product.point,
          imagePath: product.product.imagePath,
          price: product.price,
          isPriceList: product.product.isPriceList,
          regularPrice: product.product.regularPrice,
        },
      })),
      memberCheckResult: {
        exist: !!data.member.id,
        data: data.member as Member,
      },
    });
    setTimeInMinutes(data.timeInMinutes);
  });

  const { bookingData } = options;

  React.useEffect(() => {
    if (!mode && bookingData) {
      formik.setValues({
        ...formik.values,
        phoneNumber: bookingData.member.phoneNumber || '',
        customerName: bookingData.member.name || '',
        email: bookingData.member.email || '',
        isGuest: !bookingData.member.id,
        bookingId: bookingData.id,
        stylistId: bookingData.stylist ? bookingData.stylist.id : '',
        services: options.bookingData.services.map((service: any) => ({
          price: service.price,
          totalPrice: service.price,
          qty: 1,
          timeInMinutes: service.timeInMinutes,
          service: {
            id: service.id,
            name: service.name,
            imagePath: '',
            point: service.point || 0,
            price: service.price,
            timeInMinutes: service.timeInMinutes,
          },
        })),
        products: options.bookingData.products.map((product: any) => ({
          price: product.price,
          totalPrice: product.price * product.qty,
          qty: product.qty,
          product: {
            id: product.id,
            name: product.name,
            imagePath: '',
            point: product.point || 0,
            price: product.price,
          },
        })),
        memberCheckResult: {
          exist: !!bookingData.member.id,
          data: bookingData.member as Member,
        },
      });
    }
  }, [mode, bookingData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Calculate Payment Value
  const { setFieldValue } = formik;
  const {
    services: fieldServices,
    products: fieldProducts,
    voucher: fieldVoucher,
  } = formik.values;
  React.useEffect(() => {
    const servicesSubtotal = fieldServices.reduce(
      (prev, next) => prev + parseFloat(`${next.price}`),
      0,
    );
    const productsSubtotal = fieldProducts.reduce(
      (prev, next) => prev + parseFloat(`${next.price}`) * next.qty,
      0,
    );

    const discount = fieldVoucher ? fieldVoucher.calculatedDiscount : 0;

    const subtotal = servicesSubtotal + productsSubtotal;
    const totalPay = subtotal - discount;
    // TODO: temp use continous setFieldValue until this get resolved https://github.com/jaredpalmer/formik/pull/1513
    setFieldValue('discount', discount);
    setFieldValue('subTotal', subtotal);
    setFieldValue('totalPay', totalPay);
  }, [fieldServices, fieldProducts, fieldVoucher, setFieldValue]);

  return {
    formik,
    isLoading: isLoading || isLoadingBooking || formik.isSubmitting,
    setIsLoading,
    booking: bookingResultData?.booking.data,
    paymentMethods,
  };
}
