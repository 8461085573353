import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Loader from 'react-loader-spinner';
import localForage from 'localforage';
import ky from 'ky';
import { decamelizeKeys } from 'humps';
import { getAuthenticationToken } from 'utils/storage';
import color from 'styles/color';
import size from 'styles/size';
import { toast } from 'react-toastify';
import { PrefixUrl } from './prefix-modifier/utils';

interface Props {
  needSync: boolean;
  children: React.ReactNode;
}

type SyncState = {
  needSync: boolean;
  syncError?: boolean;
};

const SyncLoadingView = (props: Props) => {
  const [syncState, setSyncState] = React.useState<SyncState>({
    needSync: props.needSync,
    syncError: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {}, []);

  const syncData = React.useCallback(async () => {
    const bookings: any = await localForage.getItem('bookings');
    const transactions: any = await localForage.getItem('transactions');
    const token = await getAuthenticationToken();
    const baseUri = PrefixUrl.isStaging()
      ? PrefixUrl.get()
      : PrefixUrl.defaultUrl;

    try {
      if (bookings && bookings !== 'undefined') {
        const currentBooking = bookings.map((booking: any) => {
          return decamelizeKeys(booking);
        });

        try {
          setIsLoading(true);
          await ky.post(baseUri + '/sync-booking', {
            headers: {
              Authorization: `Bearer ${token || ''}`,
              'content-type': 'application/json',
              Accept: 'application/json',
            },
            json: {
              bookings: currentBooking,
            },
          });

          await localForage.removeItem('bookings');

          if (!transactions || (transactions && transactions === 'undefined')) {
            setIsLoading(false);
            setSyncState((prev) => ({
              ...prev,
              needSync: false,
            }));
            toast.success('Sync Berhasil');
          }
        } catch (e) {
          throw new Error('');
        }
      }

      if (transactions && transactions !== 'undefined') {
        const currentTransaction = transactions.map((transaction: any) => {
          return decamelizeKeys(transaction);
        });

        try {
          if (!bookings || (bookings && bookings === 'undefined')) {
            setIsLoading(true);
          }
          await ky.post(baseUri + '/sync-transaction', {
            headers: {
              Authorization: `Bearer ${token || ''}`,
              'content-type': 'application/json',
              Accept: 'application/json',
            },
            json: {
              transactions: currentTransaction,
            },
          });

          await localForage.removeItem('transactions');

          setIsLoading(false);
          setSyncState((prev) => ({
            ...prev,
            needSync: false,
          }));
          toast.success('Sync Berhasil');
        } catch (e) {
          throw new Error('');
        }
      }
    } catch (e) {
      setSyncState((prev) => ({
        ...prev,
        syncError: true,
      }));
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (syncState.needSync) {
      syncData();
    }
  }, [syncData, syncState.needSync]);

  return (
    <>
      {props.children}
      {syncState.needSync && (
        <LoadingContainer>
          {isLoading ? (
            <RowContainer>
              <Text>Sync Offline Data</Text>
              <Loader type="ThreeDots" color="#FFFFFF" height={60} width={60} />
            </RowContainer>
          ) : (
            <ColumnContainer>
              <Text color="black">Sync Gagal</Text>
              <InfoBanner>silahkan hubungi kami jika masih gagal</InfoBanner>
              <Button onClick={syncData}>Retry</Button>
            </ColumnContainer>
          )}
        </LoadingContainer>
      )}
    </>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgb(0.5, 0.5, 0.5, 0.6);
`;

const Text = styled.span<{ color?: string }>`
  font-size: 32px;
  margin-right: 8px;
  color: #ffffff;
  font-weight: bold;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

const InfoBanner = styled.span`
  font-size: 16px;
  background-color: #90c0f4;
  color: #ffffff;
  padding: 5px;
  border-radius: 10px;
  font-weight: bold;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${color.white};
  border-radius: 8px;
  padding: 16px;
`;

const Button = styled.button`
  width: 100%;
  background-color: ${color.black};
  border: 1px solid ${color.black};
  color: white;
  margin-top: 16px;
  cursor: pointer;
  font-size: ${size.inputH4};
  font-weight: bold;
  outline: none;
  border-radius: ${size.inputBorder};
  min-height: ${size.inputMinHeight};
  white-space: nowrap;
  &:hover {
    transform: scale(1.02);
  }
`;

export default SyncLoadingView;
