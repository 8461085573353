import config from 'config/config';

export class PrefixUrl {
  static readonly defaultUrl: string = `${config.apiEndpoint}/api/pos`;
  static readonly key = 'prefix-url';
  static isStaging() {
    return PrefixUrl.defaultUrl.includes('staging');
  }
  static get() {
    const url = localStorage.getItem(PrefixUrl.key) as string | null;

    if (PrefixUrl.isStaging()) {
      return url || PrefixUrl.defaultUrl;
    }

    return PrefixUrl.defaultUrl;
  }
  static set(url: string = PrefixUrl.defaultUrl) {
    localStorage.setItem(PrefixUrl.key, url);
    return url;
  }
}
