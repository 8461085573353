export default {
  quantity: 'Jumlah',
  service_type: 'Jenis Treatment',
  choose_stylist: 'Pilih Penata Rambut',
  product_type: 'Jenis Produk',
  add_booking: 'Tambah Pesanan',
  random_stylist: 'Acak Penata Rambut',
  all_time: 'Semua Waktu',
  success_booking_text: 'Pesanan berhasil dibuat',
  error_booking_text: 'Pesanan gagal dibuat',
  based_on_time: 'Berdasarkan Waktu',
  off: 'OFF',
  on: 'ON',
  pending: 'Pending',
  quota: 'Jatah',
  request: 'Permintaan',
};
