import * as React from 'react';
import { Container, Panel, Title } from './style';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, Field, Button } from 'components/element';
import {
  useMutationLogin,
  useStateSetLoginToken,
} from 'repositories/auth/auth.mutation';
import { setAuthenticationToken, getAuthenticationToken } from 'utils/storage';
import localForage from 'localforage';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { toast } from 'react-toastify';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export default function Login(props: any) {
  const { t } = useTranslation();
  const [submitLogin] = useMutationLogin();
  const { mode } = useNetworkStatus();

  const [setLoginToken] = useStateSetLoginToken();

  const formik = useFormik<{
    email: string;
    password: string;
  }>({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnMount: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const { email, password } = values;
      const prevToken = await getAuthenticationToken();

      try {
        const result = await submitLogin({
          variables: { payload: { email, password } },
        });
        await localForage.setItem('adminEmail', email);

        if (mode) {
          await setAuthenticationToken(result.data?.auth.data.token);
        }

        await setLoginToken({
          variables: {
            token: mode
              ? result.data?.auth.data.token
              : (prevToken as any) || 'fakeToken',
          },
        });
      } catch (e) {
        const err = e as any;
        if (!mode) {
          toast.error(err?.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container>
      <Panel>
        <Title>{t('common.appName')}</Title>
        <Form formik={formik}>
          <Field type="email" name="email" placeholder={t('common.email')} />
          <Field
            type="password"
            name="password"
            placeholder={t('common.password')}
          />
          <Button
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!formik.isValid}
          >
            {t('common.signIn')}
          </Button>
        </Form>
      </Panel>
    </Container>
  );
}
