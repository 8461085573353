import React from 'react';
import styled, { css } from 'styled-components';
import color from 'styles/color';
import { P } from './element';
import { pad } from 'utils/date';
import { string2money } from 'utils/string';
import { SingleValueProps } from 'react-select';
import { ProductOption } from './transaction/OutletProductsInput';
import { ServiceOption } from './transaction/OutletServiceInput';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { Price, ProductPrice } from './style';
import PriceComponent from './PriceComponent';

export function ServiceSingleValue(props: SingleValueProps<ServiceOption>) {
  const { cx, getStyles, selectProps, data, isDisabled, className } = props;

  const { mode } = useNetworkStatus();

  const {
    name,
    timeInMinutes,
    price,
    isPriceList,
    regularPrice,
    imagePath,
    minPrice,
    maxPrice,
    minRegularPrice,
    maxRegularPrice,
  } = data.extra;

  return (
    <Container
      className={
        cx(
          css(getStyles('singleValue', props) as any) as any,
          {
            'single-value': true,
            'single-value--is-disabled': isDisabled,
          }.toString(),
          className,
        ) as string
      }
    >
      {mode && (
        <ImageContainer>
          <Image src={imagePath} alt="" />
        </ImageContainer>
      )}
      <TextContainer>
        <Space>
          <Text>{name}</Text>
          {timeInMinutes && (
            <Time>{`${pad(Math.floor(timeInMinutes / 60))} : ${pad(
              timeInMinutes % 60,
            )}`}</Time>
          )}
        </Space>
        <PriceComponent
          {...{
            price,
            isPriceList,
            regularPrice,
            minPrice,
            maxPrice,
            minRegularPrice,
            maxRegularPrice,
            isSelected: true,
            fontSize: 'small',
          }}
        />
      </TextContainer>
    </Container>
  );
}

export function ProductSingleValue(props: SingleValueProps<ProductOption>) {
  const {
    cx,
    getStyles,
    selectProps,
    data,
    isDisabled,
    className,
    ...restProps
  } = props;

  const { mode } = useNetworkStatus();

  const { price, isPriceList, regularPrice, imagePath } = data.extra;

  return (
    <Container
      className={
        cx(
          css(getStyles('singleValue', props) as any) as any,
          {
            'single-value': true,
            'single-value--is-disabled': isDisabled,
          }.toString(),
          className,
        ) as string
      }
    >
      {mode && (
        <ImageContainer>
          <Image src={imagePath} alt="" />
        </ImageContainer>
      )}
      <TextContainer>
        <Space>
          <ProductText>{data.label}</ProductText>
        </Space>
        <PriceComponent
          {...{
            price,
            isPriceList,
            regularPrice,
            isSelected: true,
            fontSize: 'small',
          }}
        />
      </TextContainer>
    </Container>
  );
}

export const OLD_ServiceSingleValue = ({
  cx,
  getStyles,
  selectProps,
  data,
  isDisabled,
  className,
  ...props
}: any) => {
  return (
    <Container
      className={cx(
        css(getStyles('singleValue', props)),
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
        className,
      )}
    >
      <ImageContainer>
        <Image src={data.image} alt="" />
      </ImageContainer>
      <TextContainer>
        <Space>
          <Text>{data.label}</Text>
          {data.time && (
            <Time>{`${pad(Math.floor(data.time / 60))} : ${pad(
              data.time % 60,
            )}`}</Time>
          )}
        </Space>
        <Price isSelected={false}>Rp. {string2money(data.price)}</Price>
      </TextContainer>
    </Container>
  );
};

export const OLD_ProductSingleValue = ({
  cx,
  getStyles,
  selectProps,
  data,
  isDisabled,
  className,
  ...props
}: any) => {
  return (
    <Container
      className={cx(
        css(getStyles('singleValue', props)),
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled,
        },
        className,
      )}
    >
      <ImageContainer>
        <Image src={data.image} alt="" />
      </ImageContainer>
      <TextContainer>
        <Space>
          <ProductText>{data.label}</ProductText>
        </Space>
        <ProductPrice>Rp. {string2money(data.price)}</ProductPrice>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 90%;
  position: absolute;
  height: 100%;
  flex-direction: row;
  justify-content: center;
`;

const Image = styled.img`
  width: 32px;
  height: 100%;
  border-radius: 5px;
`;

const Text = styled(P)`
  font-size: 10px;
`;

const Time = styled(P)`
  color: ${color.neutralSpindle};
  font-size: 8px;
`;

const ImageContainer = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const Space = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductText = styled(P)`
  font-size: 8px;
`;
