import * as React from 'react';
import {
  CheckboxContainer,
  CheckboxInput,
  Text,
  ToggleContainer,
} from 'pages/bookingManagement/style';
import { useTranslation } from 'react-i18next';
import { FormikResult } from '../../../typings/formik';
import { OutletStylist, useQueryGetOutletStylists } from 'repositories/outlet';
import { format } from 'date-fns';
import { Field, P } from 'components/element';
import { useQueryGetBookingSheetAvailableTimeWithoutParams } from 'repositories/booking/booking.query';
import { TransactionService } from 'repositories/transaction';
import { pad } from 'utils/date';
import Switch from 'react-toggle';
import useAppState from 'hooks/useAppState';
import { FormikValues } from 'formik';
import Select, { onSelect } from 'components/element/Select';
import styled from 'styled-components';
import { string2money } from 'utils/string';

export interface FormField {
  stylist: string;
  services: TransactionService[];
  time: number | undefined;
  timeInMinutes: number;
  isPending?: boolean;
  isStylistFetch: boolean;
  isRandomStylist: boolean;
  isOperationalHour: boolean;
}

interface InputProps<T extends FormikValues> {
  date: Date;
  formik: FormikResult<T>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  usePending?: boolean;
  onChange?: (value: any) => void;
}

export interface StylistOption {
  value: string;
  label: string;
  extra: OutletStylist;
}

export interface StylistInputRef {
  getSelectedStylist: () => StylistOption;
  setSelectedStylist: (stylist: StylistOption | null) => void;
}

const OutletStylistWithTimeInput = React.forwardRef(
  <T extends FormField>(props: InputProps<T>, ref) => {
    const { formik, date, usePending = true, onChange } = props;
    const { t } = useTranslation();
    const { currentOutlet } = useAppState();

    const [
      selectedStylist,
      setSelectedStylist,
    ] = React.useState<StylistOption | null>(null);

    React.useImperativeHandle(ref, () => ({
      getSelectedStylist: () =>
        selectedStylist ?? {
          value: '',
          label: '',
          extra: {} as OutletStylist,
        },
      setSelectedStylist: (stylist) => {
        setSelectedStylist(stylist);
      },
    }));

    const services = React.useMemo(() => {
      return formik.values.services
        .map((service) => service.id || service.service.id)
        .join(',');
    }, [formik.values.services]);

    const outletId = currentOutlet?.id || '';

    const [loadStylists, getStylists] = useQueryGetOutletStylists(
      outletId,
      format(date, 'yyyy-MM-dd'),
      !formik.values.isStylistFetch || (!!formik.values.isPending && usePending)
        ? undefined
        : formik.values.time,
      !formik.values.isStylistFetch || (!!formik.values.isPending && usePending)
        ? undefined
        : formik.values.timeInMinutes,
      services,
    );

    const [
      loadBookingSheetTime,
      { loading, data },
    ] = useQueryGetBookingSheetAvailableTimeWithoutParams();

    React.useEffect(() => {
      if (outletId && !getStylists.called) {
        loadStylists();
      }
    }, [getStylists, loadStylists, outletId]);

    React.useEffect(() => {
      if (
        !formik.values.isStylistFetch &&
        formik.values.stylist &&
        formik.values.services.length
      ) {
        loadBookingSheetTime({
          variables: {
            outletId,
            date: format(date, 'yyyy-MM-dd'),
            timeInMinutes: formik.values.timeInMinutes,
            stylistId: formik.values.stylist,
            isAllTime: formik.values.isOperationalHour ? 1 : 0,
          },
        });
      }
    }, [
      date,
      formik.values.isOperationalHour,
      formik.values.isStylistFetch,
      formik.values.services.length,
      formik.values.stylist,
      formik.values.timeInMinutes,
      loadBookingSheetTime,
      outletId,
    ]);

    React.useEffect(() => {
      if (formik.values.isStylistFetch && formik.values.services.length) {
        loadBookingSheetTime({
          variables: {
            outletId,
            date: format(date, 'yyyy-MM-dd'),
            timeInMinutes: formik.values.timeInMinutes,
            stylistId: '%',
            isAllTime: formik.values.isOperationalHour ? 1 : 0,
          },
        });
      }
    }, [
      date,
      formik.values.isOperationalHour,
      formik.values.isStylistFetch,
      formik.values.services.length,
      formik.values.timeInMinutes,
      loadBookingSheetTime,
      outletId,
    ]);

    const bookingTime = React.useMemo(() => {
      if (data && data.bookingSheetAvailableTime.data) {
        return data.bookingSheetAvailableTime.data.map((time: any) => ({
          value: time.minute,
          label: `${pad(Math.floor(time.minute / 60))} : ${pad(
            time.minute % 60,
          )}`,
        }));
      }
      return [];
    }, [data]);

    const stylists = React.useMemo(() => {
      if (getStylists.data?.outletStylists.data) {
        return getStylists.data.outletStylists.data.map((stylist: any) => ({
          value: stylist.id,
          label: stylist.name,
          extra: stylist,
        }));
      }
      return [];
    }, [getStylists.data]);

    const value = React.useMemo(() => {
      const updatedStylist = selectedStylist
        ? stylists.find((stylist) => stylist.value === selectedStylist.value)
        : null;
      setSelectedStylist(updatedStylist);
      onChange?.(updatedStylist?.extra);
      return updatedStylist;
    }, [stylists, selectedStylist]);

    const onClickPending = React.useCallback(() => {
      formik.setValues({
        ...formik.values,
        isPending: !formik.values.isPending,
      });
    }, [formik]);

    const onClickRandomStylist = React.useCallback(() => {
      formik.setValues({
        ...formik.values,
        isRandomStylist: !formik.values.isRandomStylist,
      });
    }, [formik]);

    const onClickOperationalHour = React.useCallback(() => {
      formik.setValues({
        ...formik.values,
        isOperationalHour: !formik.values.isOperationalHour,
      });
    }, [formik]);

    const onClickStylistFetch = React.useCallback(() => {
      formik.setValues({
        ...formik.values,
        isStylistFetch: !formik.values.isStylistFetch,
      });
    }, [formik]);

    const content = React.useMemo(() => {
      if (!formik.values.isStylistFetch || formik.values.isPending) {
        return (
          <>
            <CheckboxContainer>
              <CheckboxInput
                type="checkbox"
                checked={formik.values.isRandomStylist}
                onClick={onClickRandomStylist}
              />
              <Text>{t('booking.random_stylist')}</Text>
            </CheckboxContainer>
            <FieldContainer>
              <Select<StylistOption>
                isMulti={false}
                name="stylist"
                isLoading={getStylists.loading && !getStylists.called}
                options={stylists}
                value={value}
                formatOptionLabel={StylistSelect}
                placeholder={t('booking.choose_stylist')}
                onChange={onSelect(({ singleValue }) => {
                  onChange?.(singleValue?.extra);
                  setSelectedStylist(singleValue as StylistOption);
                })}
              />
            </FieldContainer>
            {!formik.values.isPending && (
              <>
                <CheckboxContainer>
                  <CheckboxInput
                    type="checkbox"
                    checked={formik.values.isOperationalHour}
                    onClick={onClickOperationalHour}
                  />
                  <Text>{t('booking.all_time')}</Text>
                </CheckboxContainer>
                <Field
                  type="select"
                  name="time"
                  // isDisabled={loading}
                  isLoading={loading}
                  options={bookingTime}
                  placeholder={t('common.choose_time')}
                />
              </>
            )}
          </>
        );
      }
      return (
        <>
          <CheckboxContainer>
            <CheckboxInput
              type="checkbox"
              checked={formik.values.isOperationalHour}
              onClick={onClickOperationalHour}
            />
            <Text>{t('booking.all_time')}</Text>
          </CheckboxContainer>
          <Field
            type="select"
            name="time"
            isDisabled={loading}
            isLoading={loading}
            options={bookingTime}
            placeholder={t('common.choose_time')}
          />
          <CheckboxContainer>
            <CheckboxInput
              type="checkbox"
              checked={formik.values.isRandomStylist}
              onClick={onClickRandomStylist}
            />
            <Text>{t('booking.random_stylist')}</Text>
          </CheckboxContainer>
          <FieldContainer>
            <Select<StylistOption>
              isMulti={false}
              name="stylist"
              isLoading={getStylists.loading && !getStylists.called}
              options={stylists}
              value={selectedStylist}
              formatOptionLabel={StylistSelect}
              placeholder={t('booking.choose_stylist')}
              onChange={onSelect(({ singleValue }) => {
                onChange?.(singleValue?.extra);
                setSelectedStylist?.(singleValue as StylistOption);
              })}
            />
          </FieldContainer>
        </>
      );
    }, [
      bookingTime,
      formik.values.isOperationalHour,
      formik.values.isPending,
      formik.values.isRandomStylist,
      formik.values.isStylistFetch,
      getStylists.called,
      getStylists.loading,
      loading,
      onClickOperationalHour,
      onClickRandomStylist,
      stylists,
      t,
    ]);

    return (
      <>
        {usePending && (
          <CheckboxContainer>
            <CheckboxInput
              type="checkbox"
              checked={formik.values.isPending}
              onClick={onClickPending}
            />
            <Text>{t('booking.pending')}</Text>
          </CheckboxContainer>
        )}
        {!formik.values.isPending && (
          <ToggleContainer>
            <Text noMargin>{`${t('booking.based_on_time')} (${
              !formik.values.isStylistFetch ? t('booking.off') : t('booking.on')
            })`}</Text>
            <Switch
              checked={formik.values.isStylistFetch}
              onChange={onClickStylistFetch}
            />
          </ToggleContainer>
        )}
        {content}
      </>
    );
  },
);

OutletStylistWithTimeInput.displayName = 'OutletStylistWithTimeInput';

export default OutletStylistWithTimeInput;

export function StylistSelect(option: StylistOption) {
  const { label, extra } = option;
  return (
    <Container>
      <Label>{label}</Label>
      <Price>
        {extra.totalPrice ? `Rp ${string2money(extra.totalPrice)}` : ''}
      </Price>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
`;

const Label = styled(P)``;
const Price = styled(P)``;

const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;
