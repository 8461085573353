import * as React from 'react';
import { Price, PriceContainer, StrikePrice } from './style';
import { string2money } from 'utils/string';

interface Props {
  price: number;
  isPriceList: boolean;
  regularPrice: number;
  isSelected: boolean;
  fontSize?: 'default' | 'small';
  minPrice?: number;
  maxPrice?: number;
  minRegularPrice?: number;
  maxRegularPrice?: number;
  fixedPrice?: number;
}

export default function PriceComponent(props: Props) {
  const {
    price,
    minPrice,
    maxPrice,
    minRegularPrice,
    maxRegularPrice,
    isPriceList,
    regularPrice,
    isSelected,
    fontSize = 'default',
    fixedPrice,
  } = props;

  const hasDynamicPrice =
    minPrice !== maxPrice || minRegularPrice !== maxRegularPrice;

  if (fixedPrice) {
    return (
      <PriceContainer>
        <Price fontSize={fontSize} isSelected={isSelected}>{`Rp. ${string2money(
          fixedPrice,
        )}`}</Price>
      </PriceContainer>
    );
  }

  return (
    <PriceContainer>
      <Price fontSize={fontSize} isSelected={isSelected}>
        {`Rp. ${string2money(minPrice || price)} ${
          hasDynamicPrice ? `- ${string2money(maxPrice || 0)}` : ''
        }`}
      </Price>

      {isPriceList && (
        <StrikePrice fontSize={fontSize}>
          {`Rp. ${string2money(
            parseFloat((minRegularPrice || regularPrice).toString()),
          )} ${
            hasDynamicPrice
              ? `- ${string2money(
                  parseFloat((maxRegularPrice || 0).toString()),
                )}`
              : ''
          }`}
        </StrikePrice>
      )}
    </PriceContainer>
  );
}
