import * as React from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import {
  Outlet,
  OutletService,
  OutletProduct,
  OutletStylist,
  StylistCommission,
  StylistCommissionDetail,
} from './outlet.model';
import { OUTLET_FRAGMENT, ADMIN_OUTLET_FRAGMENT } from './outlet.fragment';
import { useNetworkStatus } from 'containers/NetworkStatusContainer';
import { MasterData } from 'repositories/common/common.model';

export const GET_OUTLETS = gql`
  query getOutlets {
    outlets @rest(type: "OutletPayload", path: "/outlets") {
      data @type(name: "Outlet") {
        ...outletFragment
      }
    }
  }
  ${OUTLET_FRAGMENT}
`;

export const GET_ADMIN_OUTLETS = gql`
  query getOutlets {
    outlets @rest(type: "AdminOutletPayload", path: "/admins/outlets") {
      data @type(name: "AdminOutlet") {
        ...outletFragment
      }
    }
  }
  ${ADMIN_OUTLET_FRAGMENT}
`;
export interface GetOutletResult {
  outlets: { data: Outlet[] };
}

export const GET_CURRENT_OUTLET = gql`
  {
    loggedInOutletId @client
  }
`;

const GET_OUTLET_SERVICES = gql`
  query getOutletServices($outletId: String!) {
    outletServices(outletId: $outletId)
      @rest(
        type: "OutletServicesPayload"
        path: "/outlets/{args.outletId}/services"
      ) {
      data @type(name: "OutletService") {
        id
        name
        imagePath
        price
        minPrice
        maxPrice
        minRegularPrice
        maxRegularPrice
        timeInMinutes
        isPriceList
        regularPrice
      }
    }
  }
`;

const GET_OUTLET_PRODUCTS = gql`
  query getOutletProducts($outletId: String!) {
    outletProducts(outletId: $outletId)
      @rest(
        type: "OutletProductsPayload"
        path: "/outlets/{args.outletId}/products"
      ) {
      data @type(name: "OutletProduct") {
        id
        code
        name
        imagePath
        description
        price
        productDetails @type(name: "ProductDetail") {
          id
          index
          filePath
        }
        qty
        isPriceList
        regularPrice
      }
    }
  }
`;

const GET_OUTLET_STYLISTS = gql`
  query getOutletStylists(
    $outletId: String!
    $date: String!
    $startMinute: Integer
    $timeInMinutes: Integer
    $services: String
  ) {
    outletStylists(
      outletId: $outletId
      date: $date
      start_minute: $startMinute
      time_in_minutes: $timeInMinutes
      services: $services
    )
      @rest(
        type: "OutletStylistsPayload"
        path: "/outlets/{args.outletId}/stylists?{args}"
      ) {
      data @type(name: "OutletStylist") {
        id
        name
        email
        phoneNumber
        rate
        rateCount
        imagePath
        totalPrice
        service {
          id
          name
          price
        }
      }
    }
  }
`;

const GET_OUTLET_STYLISTS_COMMISSION = gql`
  query getOutletStylists(
    $outletId: String!
    $dateFrom: String!
    $dateTo: String!
  ) {
    outletStylistCommission(
      outletId: $outletId
      dateFrom: $dateFrom
      dateTo: $dateTo
    )
      @rest(
        type: "OutletStylistCommissionPayload"
        path: "/stylist-commission-summary?outlet_id={args.outletId}&date_from={args.dateFrom}&date_to={args.dateTo}"
      ) {
      filterDate
      stylistId
      stylistName
      serviceCnt
      totalStylistCommission
    }
  }
`;

const GET_OUTLET_STYLISTS_COMMISSION_DETAIL = gql`
  query getOutletStylists(
    $outletId: String!
    $dateFrom: String!
    $dateTo: String!
    $stylistId: String!
  ) {
    outletStylistCommissionDetail(
      outletId: $outletId
      dateFrom: $dateFrom
      dateTo: $dateTo
      stylistId: $stylistId
    )
      @rest(
        type: "OutletStylistCommissionDetailPayload"
        path: "/stylist-commission-detail?outlet_id={args.outletId}&date_from={args.dateFrom}&date_to={args.dateTo}&stylist_id={args.stylistId}"
      ) {
      serviceCnt
      serviceId
      serviceName
      totalStylistCommission
    }
  }
`;

export function useStateGetCurrentOutlet() {
  return useQuery<{ loggedInOutletId: string | null }>(GET_CURRENT_OUTLET);
}

export function useQueryGetOutlets() {
  return useQuery<GetOutletResult>(GET_OUTLETS);
}

export function useQueryGetAdminOutlets() {
  return useLazyQuery<GetOutletResult>(GET_ADMIN_OUTLETS);
}

type OutletServiceResultData = { outletServices: { data: OutletService[] } };

function GetOfflineService(
  outletId: string,
  onCompleted: (data: OutletServiceResultData) => void,
) {
  React.useEffect(() => {
    let result: MasterData | string | null = localStorage.getItem('masterData');

    if (result && result !== 'undefined') {
      result = JSON.parse(result) as MasterData;

      const outlet = result?.outlets.find(
        (outlet: any) => outlet.id === outletId,
      );

      if (outlet) {
        onCompleted({
          outletServices: {
            data: (outlet.services as any) || [],
          },
        });
      }
    }
  }, [onCompleted, outletId]);

  return null;
}

export function useQueryGetOutletServices(
  outletId: string,
  onCompleted?: (data: OutletServiceResultData) => void,
) {
  const { mode } = useNetworkStatus();

  const queryResult = useQuery<OutletServiceResultData>(GET_OUTLET_SERVICES, {
    variables: {
      outletId,
    },
    onCompleted: mode ? onCompleted : undefined,
  });

  if (!mode) {
    onCompleted && GetOfflineService(outletId, onCompleted);
  }

  return mode ? queryResult : {};
}

type OutletProductResultData = { outletProducts: { data: OutletProduct[] } };

function GetOfflineProduct(
  outletId: string,
  onCompleted: (data: OutletProductResultData) => void,
) {
  React.useEffect(() => {
    let result: MasterData | string | null = localStorage.getItem('masterData');

    if (result && result !== 'undefined') {
      result = JSON.parse(result) as MasterData;

      const outlet = result?.outlets.find(
        (outlet: any) => outlet.id === outletId,
      );

      if (outlet) {
        onCompleted({
          outletProducts: {
            data: outlet.products as any,
          },
        });
      }
    }
  }, [onCompleted, outletId]);

  return null;
}

export function useQueryGetOutletProducts(
  outletId: string,
  onCompleted?: (data: OutletProductResultData) => void,
) {
  const { mode } = useNetworkStatus();

  const queryResult = useQuery<OutletProductResultData>(GET_OUTLET_PRODUCTS, {
    variables: {
      outletId,
    },
    onCompleted: mode ? onCompleted : undefined,
  });

  if (!mode) {
    onCompleted && GetOfflineProduct(outletId, onCompleted);
  }

  return mode ? queryResult : {};
}

function GetOfflineStylist(outletId: string): any {
  let result: MasterData | string | null = localStorage.getItem('masterData');
  let stylists: any[] = [];

  if (result && result !== 'undefined') {
    result = JSON.parse(result) as MasterData;

    const outlet = result?.outlets.find(
      (outlet: any) => outlet.id === outletId,
    );

    if (outlet) {
      stylists = outlet.stylists;
    }
  }

  return [
    () => {},
    {
      data: {
        outletStylists: {
          data: stylists,
        },
      },
      loading: false,
      called: false,
    },
  ];
}

export function useQueryGetOutletStylists(
  outletId: string,
  date?: string,
  startMinute?: number,
  timeInMinutes?: number,
  services?: string,
) {
  const { mode } = useNetworkStatus();

  const queryResult = useLazyQuery<{
    outletStylists: { data: OutletStylist[] };
  }>(GET_OUTLET_STYLISTS, {
    variables: {
      outletId,
      date,
      startMinute: startMinute || null,
      timeInMinutes: timeInMinutes || null,
      services: services || undefined,
    },
  });

  return mode ? queryResult : GetOfflineStylist(outletId);
}

export function useQueryGetOutletStylistCommission(
  outletId: string,
  dateFrom: string,
  dateTo: string,
) {
  return useLazyQuery<{ outletStylistCommission: StylistCommission[] }>(
    GET_OUTLET_STYLISTS_COMMISSION,
    {
      variables: {
        outletId,
        dateFrom,
        dateTo,
      },
    },
  );
}

export function useQueryGetOutletStylistCommissionDetail(
  outletId: string,
  dateFrom: string,
  dateTo: string,
  stylistId: string,
) {
  return useQuery<{ outletStylistCommissionDetail: StylistCommissionDetail[] }>(
    GET_OUTLET_STYLISTS_COMMISSION_DETAIL,
    {
      variables: {
        outletId,
        dateFrom,
        dateTo,
        stylistId,
      },
    },
  );
}
